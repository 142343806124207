import React, { createContext, useReducer, useState } from "react";
import { reducer } from "../reducers/actions";
// import { useHistory } from "react-router-dom";

export const GlobalContext = createContext();

export default function Context({ children }) {
  const initialState = {
    bundles: [],
    userPlaylists: []
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  // let initialStep;
  // const history = useHistory();

  // if (
  //   history.location.state &&
  //   (history.location.state.hostFindingWayBack ||
  //     history.location.state.activeStep)
  // ) {
  //   initialStep = history.location.state.hostFindingWayBack ? 5 : 2;
  // } else {
  //   initialStep = 1;
  // }
  const [gameDetails, setGameDetails] = useState({});

  const contextData = {
    state: state,
    dispatch: dispatch,
    gameDetails,
    setGameDetails
  };
  return (
    <GlobalContext.Provider value={contextData}>
      {children}
    </GlobalContext.Provider>
  );
}
