import "./style.css";

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import LogoutImage from "../../../assets/images/logout-icon.png";
import mobileLogo from "../../../assets/images/mobile-logo.png";
import { getUserData } from "../../../containers/apollo/authentication";
import routes from "../../../routes";
import { GlobalContext } from "../../../GlobalState/context";
import * as Screeb from "@screeb/sdk-browser";
import GET_BUNDLES from "../../../containers/graphql/queries/getBundles";
import PricingIcon from "../../../assets/images/PricingIcon.svg";
import UserIcon from "../../../assets/images/user-icon.svg";

import { client } from "../../../containers/apollo";
import { POPULATE_BUNDLES } from "../../../GlobalState/reducers/types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { onClickAnalytics } from "../../../utilities/analytics";
let loading, error, data;
async function getBundles() {
  loading, error, (data = await client.query({ query: GET_BUNDLES }));
  if (!loading) {
    if (error) {
      showErrorMessage(error.message);
    }
    if (data) {
      return data.data.listBundle.data;
      // setBundleLoading(false);
      // setBundles(data.data.listBundle.data);
    }
  }
}

const SIDE_MENUS = [
  {
    name: "Dashboard",
    path: routes.USER_DASHBOARD
  },
  {
    name: "Join a Game",
    path: routes.USER_JOIN_GAME
  },
  // {
  //   name: "Our Bundles",
  //   path: routes.OUR_BUNDLES,
  // },
  {
    name: "Host a Game",
    path: routes.USER_HOST_GAME
  },

  {
    name: "How to Play",
    path: "/user/how-to-play"
  }
  // {
  //   name: "Settings",
  //   path: routes.SETTINGS,
  // },
];

function truncate(input = "") {
  if (input.length > 12) {
    return input.substring(0, 6) + " ...";
  }
  return input;
}

const sidebarMenu = (
  openSidebar,
  setOpenSidebar,
  logoutUser,
  user,
  bundlename,
  showConfigurationModal,
  toggleConfigurationModal
) => {
  return (
    <div
      className={openSidebar ? "overlay" : "overlay__inactive"}
      onClick={() => {
        if (showConfigurationModal) {
          setOpenSidebar(false);
        }
      }}
    >
      {/* <div className="menu-wrapper"> */}
      <div
        className={
          openSidebar ? "menu-items__mobile" : "menu-items__mobile__inactive"
        }
      >
        <div className="close_icon_wrapper">
          <i
            className="fa fa-times close_modal_btn"
            aria-hidden="true"
            onClick={() => {
              setOpenSidebar(false);
            }}
          ></i>
        </div>
        <div className="side-menu-wrapper">
          <ul className="side-menus">
            {SIDE_MENUS.map((menu) => (
              <li
                className={`f-inter ${window.location.pathname === menu.path ? "active" : ""}`}
                key={menu.path}
              >
                <button
                  onClick={() => {
                    setOpenSidebar(false);
                  }}
                >
                  {menu.path != "/user/how-to-play" ? (
                    <Link to={`${menu.path}`}>{menu.name}</Link>
                  ) : (
                    <a href={`${routes.USER_HOW_TO_PLAY}`}>{menu.name}</a>
                  )}
                </button>
              </li>
            ))}
          </ul>
          {user?.userBundleType ? (
            <>
              <div
                style={{ marginLeft: "2rem" }}
                className={user?.userBundleType ? "bundle-wrapper-flex" : ""}
              >
                <div>
                  <div className="bundle_mobile">
                    <p style={{ marginBottom: "0px" }}>Bundle 2</p>
                  </div>
                  <p
                    className="plan-name-wrapper"
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      color: "white"
                    }}
                  >
                    {bundlename}
                  </p>
                </div>
                <div>
                  <p className="change-plan">
                    <Link to="/user/our-bundles">Change Plan</Link>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ marginLeft: "2rem" }}
                className={user?.userBundleType ? "bundle-wrapper-flex" : ""}
              >
                <p className="trial-campaign-remaining-days">
                  Free Trial{" "}
                  <span>{user?.trialCampaignRemainingDays}/30 Days Left</span>
                </p>
                <div className="bundle_mobile">
                  <p style={{ marginBottom: "0px" }}>Buy Bundle</p>
                </div>
                <p
                  className="plan-name-wrapper"
                  style={{ marginBottom: "0px", fontSize: "12px" }}
                  onClick={() => {
                    onClickAnalytics("Account Option Selected", {
                      button_name: "UpgradeAccount_Button",
                      page_name: "Host Game"
                    });
                  }}
                >
                  <Link to="/user/our-bundles">Upgrade Account</Link>
                </p>
              </div>
            </>
          )}
          {showConfigurationModal ? (
            <div
              className="config-modal"
              onClick={() => {
                toggleConfigurationModal();
                setOpenSidebar(true);
              }}
            >
              <div className="name-pricing-plan">
                <div className="username-config">
                  <img src={UserIcon} alt="user-icon" className="" />
                  <p> {truncate(user?.fullname)} </p>
                </div>
                <div className="pricing-plan">
                  <img src={PricingIcon} alt="pricing icon" />
                  <p>
                    <Link to="/user/our-bundles">Pricing Plans</Link>
                  </p>
                </div>
              </div>
              <div
                className="other-configs settings"
                onClick={() => {
                  window.location.href = routes.SETTINGS;
                }}
              >
                <i className="fal fa-cog icons"></i>
                <p>Settings</p>
              </div>
              <div className="other-configs">
                <i className="fal fa-bell icons"></i>
                <p>Notifications(coming soon)</p>
              </div>
              <div className="other-configs">
                <i class="fal fa-headphones-alt icons"></i>
                <p>Contact</p>
              </div>
            </div>
          ) : (
            <div className="logout-container_mobile">
              <div
                className="log-out-wrapper"
                onClick={() => {
                  toggleConfigurationModal();
                  setOpenSidebar(true);
                }}
              >
                <img src={UserIcon} alt="user-icon" className="" />
                <p className="text-white f-inter">
                  {" "}
                  {truncate(user?.fullname)}{" "}
                </p>
              </div>
              <p className="log-out-text" onClick={logoutUser}>
                Sign Out
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

const View = ({
  children,
  bundlename,
  user,
  toggleConfigurationModal,
  showConfigurationModal
}) => {
  const logoutUser = () =>
    (window.location.href = `${routes.LOGIN}?nState=LOGOUT`);

  const [openSidebar, setOpenSidebar] = useState(false);

  const loadUsetifulScript = () => {
    window.usetifulTags = {
      segment: "EXAMPLE_SEGMENT_NAME",
      language: "EXAMPLE_LANGUAGE_CODE",
      role: "EXAMPLE_ROLE",
      firstName: "EXAMPLE_NAME"
    };

    const script = document.createElement("script");
    script.src = "https://www.usetiful.com/dist/usetiful.js";
    script.async = true;
    script.setAttribute("id", "usetifulScript");
    script.dataset.token = "e871e0ccb8c45f0026f70c7a4febac1b";

    document.head.appendChild(script);
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      loadUsetifulScript();
    }
  }, []);

  useEffect(() => {
    Screeb.load().then(() => {
      Screeb.init("66764e6f-69e9-4ef9-a307-d1a426b83489");
      console.log("Screeb Loaded!");
    });

    // return () => {
    //   Screeb.close();
    // };
  }, []);
  return (
    <>
      {sidebarMenu(
        openSidebar,
        setOpenSidebar,
        logoutUser,
        user,
        bundlename,
        showConfigurationModal,
        toggleConfigurationModal
      )}
      <main className="dashboard-layout">
        <div className="inner-dashboard-layout">
          <div className="leftside-dashboard">
            <div className="logo-container">
              <Link to="/user/host-game">
                <img src={Logo} className="desktop_logo" alt="Muzingo" />
              </Link>
              <Link to="#">
                <img src={mobileLogo} className="mobile_logo" alt="Muzingo" />
              </Link>
            </div>
            <div className="menu-items">
              <ul className="side-menus">
                {SIDE_MENUS.map((menu) => (
                  <li
                    className={`f-inter ${window.location.pathname === menu.path ? "active" : ""}`}
                    key={menu.path}
                    id={menu.name}
                    onClick={() =>
                      onClickAnalytics(
                        `${menu.name} Sidebar Navigation Clicked`,
                        {
                          button_name: `${menu.name}_Button`,
                          page_name: `${menu.name.split(" ")[0]} Game` // e.g. Dashboard Game
                        }
                      )
                    }
                  >
                    {menu.path != "/user/how-to-play" ? (
                      <Link to={`${menu.path}`}>{menu.name}</Link>
                    ) : (
                      <a
                        href={`${routes.USER_HOW_TO_PLAY}`}
                        onClick={() =>
                          onClickAnalytics("How To Play Clicked", {
                            button_name: `${menu.name}_Button`,
                            page_name: `${menu.name.split(" ")[0]} Game` // e.g. Dashboard Game
                          })
                        }
                      >
                        {menu.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <i
              className="fa fa-bars mobilemenu"
              aria-hidden="true"
              onClick={() => {
                setOpenSidebar(true);
              }}
            ></i>{" "}
            <div className="bundle-wrapper">
              {user?.userBundleType ? (
                <div className="bundle-wrapper-flex">
                  <div>
                    <div className="bundle">
                      <p style={{ marginBottom: "0px" }}>Bundle </p>
                    </div>
                    <p className="plan-name-wrapper">
                      <span className="plan-name">{bundlename}</span>
                    </p>
                  </div>
                  <div>
                    <p className="change-plan">
                      <Link to="/user/our-bundles">Change Plan</Link>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="bundle-wrapper">
                  <p className="trial-campaign-remaining-days">
                    Free Trial{" "}
                    <span>{user?.trialCampaignRemainingDays}/30 Days Left</span>
                  </p>
                  <div className="bundle_mobile">
                    <p style={{ marginBottom: "0px" }}>Buy Bundle</p>
                  </div>
                  <p className="" style={{ marginBottom: "0px" }}>
                    <Link to="/user/our-bundles" style={{ color: "white" }}>
                      Upgrade Account
                    </Link>
                  </p>
                </div>
              )}
              {showConfigurationModal ? (
                <div
                  className="config-modal"
                  onClick={() => {
                    toggleConfigurationModal();
                  }}
                >
                  <div className="name-pricing-plan">
                    <div className="username-config">
                      <img src={UserIcon} alt="user-icon" className="" />
                      <p> {truncate(user?.fullname)} </p>
                    </div>
                    <div className="pricing-plan">
                      <img src={PricingIcon} alt="pricing icon" />
                      <p>
                        <Link to="/user/our-bundles">Pricing Plans</Link>
                      </p>
                    </div>
                  </div>
                  <div
                    className="other-configs settings"
                    onClick={() => {
                      window.location.href = routes.SETTINGS;
                    }}
                  >
                    <i className="fal fa-cog icons"></i>
                    <p>Settings</p>
                  </div>
                  <div className="other-configs">
                    <i className="fal fa-bell icons"></i>
                    <p>Notifications(coming soon)</p>
                  </div>
                  <div className="other-configs">
                    <i class="fal fa-headphones-alt icons"></i>
                    <p>Contact</p>
                  </div>
                </div>
              ) : (
                <div className="logout-container">
                  <div
                    className="log-out-wrapper"
                    onClick={() => {
                      toggleConfigurationModal();
                    }}
                  >
                    <img src={UserIcon} alt="user-icon" className="" />
                    <p className="text-white f-inter">
                      {" "}
                      {truncate(user?.fullname)}{" "}
                    </p>
                  </div>
                  <p className="log-out-text" onClick={logoutUser}>
                    Sign Out
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="main-dashboard-content">{children}</div>
        </div>
      </main>
    </>
  );
};

export default function UserDashBoardLayout(props) {
  const { user } = getUserData();
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);

  const toggleConfigurationModal = () => {
    setShowConfigurationModal(!showConfigurationModal);
  };

  const context = useContext(GlobalContext);
  useEffect(async () => {
    console.log("Rendered once");
    let bundles = await getBundles();
    let sortedBundles;
    sortedBundles = bundles.slice().sort(function (a, b) {
      return a.defaultMaxPlaylist - b.defaultMaxPlaylist;
    });
    context.dispatch({
      type: POPULATE_BUNDLES,
      payload: sortedBundles
    });
    console.log({ user });
  }, []);
  const getExactBundle = (bundleId) => {
    const bundle = context.state.bundles.find(
      (bundle) => bundle.id == bundleId
    );
    return bundle;
  };
  const currentBundle = getExactBundle(user?.userBundleType);
  const bundlename = currentBundle?.title;

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Muzingo Application</title>
      </Helmet>
      <View
        {...props}
        bundlename={bundlename}
        user={user}
        toggleConfigurationModal={toggleConfigurationModal}
        showConfigurationModal={showConfigurationModal}
      />
    </>
  );
}
