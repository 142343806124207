import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getUserData } from "./authentication";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

const BASE_URL = process.env.BASE_URL;

const httpLink = createHttpLink({
  uri: BASE_URL
});

const authLink = setContext((_, { headers }) => {
  const token = getUserData().token;
  return {
    headers: {
      ...headers,
      "Authorization-Token": token ? `${token}` : ""
    }
  };
});

const wsLink = new WebSocketLink({
  uri: `${BASE_URL == "//localhost:5500/graphql" ? "ws" : "wss"}:${BASE_URL}`,
  options: {
    reconnect: true,
    connectionParams: {
      "Authorization-Token": getUserData().token ? `${getUserData().token}` : ""
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation == "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache()
});
