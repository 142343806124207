import gql from "graphql-tag";

export default gql`
  query {
    listBundle {
      totalCount
      data {
        id
        title
        maxGameParticipant
        defaultMaxPlaylist
        costOfAcquisition
        subscriptionType
        discount
        extraCostOfUnitPlaylist
      }
    }
  }
`;
