import { makeVar, useQuery } from "@apollo/client";
import GET_USER_QUERY from "../../containers/graphql/queries/getUser";

const userData = makeVar({
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  fullname: null,
  user: null,
  isLoading: false,
  location: ""
});

export function getUserData() {
  return userData();
}

export function userLoading() {
  userData({
    ...getUserData(),
    isLoading: true
  });
}

export function userLoaded(data) {
  userData({
    ...getUserData(),
    user: data.authenticatedUserData,
    fullname: data.authenticatedUserData.fullname,
    location: data.authenticatedUserData.location,
    isAuthenticated: true,
    isLoading: false
  });
}

export function authError() {
  userData({
    ...getUserData(),
    token: null,
    user: null,
    fullname: null,
    isAuthenticated: false,
    isLoading: false
  });
}

export function loginFail() {
  userData({
    ...getUserData(),
    token: null,
    user: null,
    fullname: null,
    isAuthenticated: false,
    isLoading: false
  });
}

export function logoutSuccess() {
  localStorage.removeItem("token"),
    userData({
      ...getUserData(),
      token: null,
      user: null,
      fullname: null,
      isAuthenticated: false,
      isLoading: false
    });
}

export function registerFail() {
  userData({
    ...getUserData(),
    token: null,
    user: null,
    fullname: null,
    isAuthenticated: false,
    isLoading: false
  });
}

export function loginSuccess(data) {
  localStorage.setItem("token", data.token);
  userData({
    ...getUserData(),
    token: localStorage.getItem("token"),
    user: data.authenticatedUserData,
    fullname: data.authenticatedUserData.fullname,
    location: data.authenticatedUserData.location,
    isAuthenticated: true,
    isLoading: false
  });
}

export function registerSuccess(data) {
  localStorage.setItem("token", data.token);
  userData({
    ...getUserData(),
    token: localStorage.getItem("token"),
    user: data.authenticatedUserData,
    fullname: data.authenticatedUserData.fullname,
    location: data.authenticatedUserData.location,
    isAuthenticated: true,
    isLoading: false
  });
}
