import { getUserData } from "../../containers/apollo/authentication";

export const onUserAnalytics = (userData = {}) => {
  let user = userData;
  if (typeof globalThis.analytics != "undefined") {
    if ((userData = {})) {
      user = getUserData()?.user;
    }
    analytics.identify(user?.id, ...user);
  }
};

export const onPageViewAnalytics = (pageData = {}) => {
  let page = pageData;
  if (typeof globalThis.analytics != "undefined") {
    if ((pageData = {})) {
      // page = getUserData()?.user
      // Umar to provide detailed specs
    }
    analytics.page();
  }
};

export const onClickAnalytics = (name, details = {}) => {
  if (typeof globalThis.analytics != "undefined") {
    analytics.track(name, details);
  }
  if (typeof globalThis.gtag != "undefined") {
    // Translate the Segment tracking call to a gtag event call generically
    gtag("event", name, details);
  }
};
