import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Context from "../GlobalState/context";
import { client } from "../containers/apollo";
import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

const node_env = process.env.NODE_ENV;
if (node_env !== "development") {
  const s = document.getElementsByTagName("script")[0];

  // clarity
  const clarity = document.createElement("script");
  // Begin clarity Asynchronous Code
  clarity.innerHTML = `
(function(c,l,a,r,i,t,y){
c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "i7v7fhaoz3");
`;
  // End clarity Asynchronous Code
  clarity.type = "text/javascript";
  s.parentNode.insertBefore(clarity, s);

  // GOOGLE ANALYTICS
  // Global site tag (gtag.js) - Google Analytics begin
  const g = document.createElement("script");
  g.type = "text/javascript";
  g.async = true;
  g.src = "https://www.googletagmanager.com/gtag/js?id=G-F1KGR16NE2";
  s.parentNode.insertBefore(g, s);

  const g2 = document.createElement("script");
  g2.innerHTML = `  window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-F1KGR16NE2');
`;
  s.parentNode.insertBefore(g2, s);
  // Global site tag (gtag.js) - Google Analytics end

  // <!-- Start of Chat Code -->

  const fwChat = document.createElement("script");
  fwChat.type = "text/javascript";
  fwChat.async = true;
  fwChat.src = "//fw-cdn.com/10706911/3512887.js";
  fwChat.setAttribute("chat", "true");
  s.parentNode.insertBefore(fwChat, s);
  // <!-- End of Chat Code -->
}

export default function AppRoot() {
  return (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider
        clientId={
          "699041411987-ptj3g42kik011e4feigfefn0cku5k2bi.apps.googleusercontent.com"
        }
      >
        <Context>
          <App />
        </Context>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<AppRoot />, document.getElementById("react-app"));
