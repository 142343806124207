import gql from "graphql-tag";

export default gql`
  query {
    getUser {
      id
      fullname
      username
      email
      totalGamesPlayed
      totalGamesWon
      activePlan
      customGameCode
      userBundleType
      totalHoursPlayed
      userExperience
      accountActive
      dateRegistered
      userRemainingPlaylist
      userPlaylist
      activeTrialCampaign
      trialCampaignPlaylist
      trialCampaignRemainingDays
      trialCampaignRemainingGames
      isOnline
      location
      hostedGamesCount
      winningStreak {
        count
      }
      dailyGameStreak {
        count
      }
      gameHostedStreak {
        count
      }
    }
  }
`;
