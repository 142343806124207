import React from "react";

export const Spinner = (props) => {
  return <i className="fa fa-spinner fa-spin " {...props}></i>;
};

let mql = window.matchMedia("(max-width: 500px)");

export const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spinner
        style={{
          fontSize: mql.matches ? "30px" : "60px"
        }}
      />
    </div>
  );
};
