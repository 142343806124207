import { POPULATE_USER_PLAYLISTS, POPULATE_BUNDLES } from "./types";

export const reducer = (state, action) => {
  switch (action.type) {
    case POPULATE_BUNDLES:
      return (state = {
        ...state,
        bundles: action.payload
      });
    case POPULATE_USER_PLAYLISTS:
      return (state = {
        ...state,
        userPlaylists: action.payload
      });
    default:
      return state;
  }
};
