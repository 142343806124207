import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Loading } from "../layouts/Spinner";
import { getUserData } from "../../containers/apollo/authentication";
import UserDashBoardLayout from "../layouts/UserDashBoardLayout";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook } from "../../../growthBook.instance";
import { onUserAnalytics } from "../../utilities/analytics";

const PrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures({ autoRefresh: true });
    growthbook.setAttributes({
      url: window.location.hostname,
      userAgent: navigator.userAgent
    });
  }, []);

  if (getUserData().isLoading) {
    return <Loading />;
  } else if (!getUserData().isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          search: `?next=${window.location.href.replace(
            window.location.origin,
            ""
          )}&alert=true`,
          state: { prevPath: window.location.pathname }
        }}
      />
    );
  } else {
    onUserAnalytics(getUserData()?.user);
    return (
      <Route
        {...rest}
        render={(props) => (
          <GrowthBookProvider growthbook={growthbook}>
            <UserDashBoardLayout>
              <Component {...props} />
            </UserDashBoardLayout>
          </GrowthBookProvider>
        )}
      />
    );
  }
};

export default PrivateRoute;
