import toast from "react-hot-toast";

export default function (message) {
  toast.error(message, {
    style: {
      borderRadius: "10px",
      background: "linear-gradient(151.69deg, #65348B 5.89%, #3F2262 97.05%)",
      color: "#fff",
      padding: "18px",
      borderLeft: "4px solid #ff4b4b",
      fontFamily: "Mulish",
      fontWeight: "500"
    }
  });
}
